@import '~bootstrap/scss/bootstrap.scss';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

html, body {
  font-family: 'Open Sans', sans-serif !important;
}

.app {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  overflow: hidden;
}

.app-container {
  position: absolute;
  top: 0px; left: 0; right: 0; bottom: 0;
  overflow: scroll;
  padding-top: 76px;
}

.container-padding {
  padding: 25px;
  overflow: scroll;
  height: 100%;
  padding-bottom: 100px;
}

.room {
  position: relative;

  &, .img-room {
    border-radius: 30px;
    z-index: 1;
  }
}

.splash {
  background: transparent url('imgs/splash.jpg') top center no-repeat;
  background-size: cover;
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  z-index: 999;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 600 !important;

  small {
    display: block;
    font-size: 60%;
    margin-top: 5px;
    line-height: 125%;
  }
}

.h1, .h1 small {
  font-size: 32px !important;
}

.h2, .h2 small {
  font-size: 28px !important;
}

.h3, .h3 small {
  font-size: 24px !important;
}

.h4, .h4 small {
  font-size: 20px !important;
}

.h5, .h5 small {
  font-size: 18px !important;
}

.h6, .h6 small {
  font-size: 16px !important;
}

.mt5 { margin-top: 5px !important; }
.mt10 { margin-top: 10px !important; }
.mt15 { margin-top: 15px !important; }
.mt20 { margin-top: 20px !important; }
.mt25 { margin-top: 25px !important; }
.mt30 { margin-top: 30px !important; }
.mt35 { margin-top: 35px !important; }
.mt40 { margin-top: 40px !important; }
.mt60 { margin-top: 60px !important; }

.mb5 { margin-bottom: 5px !important; }
.mb10 { margin-bottom: 10px !important; }
.mb15 { margin-bottom: 15px !important; }
.mb20 { margin-bottom: 20px !important; }
.mb25 { margin-bottom: 25px !important; }
.mb30 { margin-bottom: 30px !important; }
.mb35 { margin-bottom: 35px !important; }
.mb40 { margin-bottom: 40px !important; }
.mb60 { margin-bottom: 60px !important; }
