.header {
    background: rgb(100,158,155);
    background: linear-gradient(90deg, rgba(100,158,155,1) 0%, rgba(206,217,85,1) 100%);
    position: absolute;
    top: 0; left: 0; right: 0;
    padding: 20px 30px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    z-index: 1;

    a, a:hover, a:link {
        color: #fff;
        text-decoration: none;
    }

    .icon {
        background-color: #b4c11d;
        border-radius: 50px;
        border: 4px solid #fff;
        box-shadow: 0px 0px 6px rgba(0,0,0,0.25);
        display: inline-block;
        width: 32px;
        height: 32px;
        text-align: center;
        line-height: 24px;
        font-size: 16px;
        vertical-align: middle;
        margin-top: -4px;
    }
}