.img-unselected {
    opacity: 0.50;
}

.img-rounded-unselected {
    border: 4px solid #eee !important;
}

.img-rounded-selected {
    border: 4px solid rgb(171, 189, 41) !important;
}

.room-selection {
    position: relative;

    .room-0,
    .room-1,
    .room-2,
    .room-3,
    .room-4,
    .room-5 {
        position: absolute;
        width: 22.5%;
    }
    .room-0, .room-1, .room-2 {
        top: 24.75%;
    }
    .room-3, .room-4, .room-5 {
        top: 60.75%;
    }
    .room-0, .room-3 {
        left: 11.00%;
    }
    .room-1, .room-4 {
        left: 34.50%;
    }
    .room-2, .room-5 {
        left: 67.25%;
    }

    .form-check {
        display: none;
    }
}