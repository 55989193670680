.button {
    background-color: #aeba1d;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 50px;
    color: #fff;
    font-weight: 600;
    position: relative;
    margin-right: 5px;
    text-transform: uppercase;
    cursor: pointer; cursor: hand;

    .icon {
        background-color: #b4c11d;
        border-radius: 50px;
        border: 4px solid #fff;
        box-shadow: 0px 0px 6px rgba(0,0,0,0.25);
        display: inline-block;
        width: 56px;
        height: 56px;
        text-align: center;
        line-height: 44px;
        font-size: 28px;
        position: absolute;
        left: -12px;
        top: -6px;
    }

    &.icon-left {
        padding-left: 56px;
    }

    &.icon-right {
        padding-right: 56px;

        .icon {
            left: auto;
            right: -12px;
        }
    }

    &.without-icon {
        padding-left: 20px;
        padding-right: 20px;
    }

    &.bg-gray {
        background-color: #e5e5e5;
        color: #555;
    }
}