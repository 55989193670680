.img-rounded, .img-rounded-small {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border: 2px solid #b4c11d;
    border-radius: 120px;
    display: inline-block;
    margin: 10px;

    img {
        width: 120px;
        margin-top: 15px;
    }
}

.img-rounded-small {
    width: 80px;
    height: 80px;

    img {
        width: 80px;
        margin-top: 10px;
    }
}

.bg-gray {
    background-color: #f5f5f5;
    border-bottom: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
}