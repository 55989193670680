.character-popup {
    position: absolute;
    background: transparent url(../imgs/popup.png) center center no-repeat;
    background-size: contain;
    overflow: hidden;
    margin-left: -11.00%;
    margin-top: -10.75%;
    z-index: 10;

    .character-popup-actions {
        padding: 2% 2% 2% 12%;
        text-align: center;

        > img {
            padding: 2.0% 2.0%;
        }
    }
}